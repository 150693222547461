<template>
  <div>
     <AdminNavBar/>
      <h1 class="purple-title text-center">Failure Alerts</h1>
      <div class="row">
        <div class="offset-md-1 col-md-2">
          <router-link to="/admin-dashboard"><button class="btn purple-btn">Back</button></router-link>
        </div>
      </div>
  </div>
</template>

<script>
import AdminNavBar from '../../components/Admin/AdminNavBar'
export default {
  components:{
    AdminNavBar
  }
}
</script>

<style>

</style>